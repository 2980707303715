<template>
    <div class="rtable">
        <table>
            <thead>
                <tr>
                    <th v-for="column in columns" v-text="column.title"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row, rowidx in data" @click="route(row)" :class="{'clickable': row.url ? true : false}">
                    <td v-for="column in columns">
                        <span v-if="typeof row[column.field] == 'boolean'"><i v-html="$icon(row[column.field] ? 'check-circle' : 'x-circle', 'solid')" :class="{'true': row[column.field], 'false': !row[column.field]}"></i></span>
                        <span v-else-if="row[column.field] && typeof row[column.field] == 'object'"><span :class="['label', row[column.field].first('').toLowerCase()]" v-text="row[column.field].first('')"></span></span>
                        <span v-else-if="row[column.field] && row[column.field].substring(0, 4) == '2023'" v-text="dayjs(row[column.field]).format('DD-MM-YYYY')"></span>
                        <span v-else v-text="row[column.field]"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import dayjs from 'dayjs'

export default {
    name: 'rtable',
    props: {
        columns: {
            type: Array,
            default: () => { return [] }
        },
        data: {
            type: Array,
            default: () => { return [] }
        }
    },
    data() {
        return {}
    },
    methods: {
        route(row) {
            if (row.url) {
                this.$router.push(row.url)
            }
        },
        dayjs
    }
}
</script>
<style lang="scss">
.rtable {
    // padding-top: 20px;
    // background-color: #FFF;

    table {
        width: 100%;

        th,
        td {
            // line-height: 26px;
            text-align: left;
            border-bottom: 1px solid var(--bg);
        }

        tr:last-child td {
            // border-bottom: none;
        }

        tr.clickable {
            cursor: pointer;

            &:hover {
                background-color: var(--bg);
            }
        }

        td,
        th {
            padding: 8px 15px;
            vertical-align: middle;
            line-height: 20px;

            // max-width: 130px;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;

            &:first-child span {
                max-width: 300px;
            }

            >span {
                display: inline-block;
                height: 24px;
                line-height: 24px;
            }

            // div.txt {
            //     // line-height: 26px;
            //     padding: 3px 0;
            //     display: block;
            // }
            // div.img {
            //     // line-height: 26px;
            //     // margin: 3px 0;
            //     // display: block;


            // }
        }

        thead {
            // border-bottom: var(--border);
            font-weight: 700;

            tr {
                th {
                    // white-space: nowrap;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // background-color: rgb(243,244,247); //#ecf0f199;;
                }
            }
        }

        tbody {
            tr {
                // cursor: pointer;

                // &:hover {
                // background-color: rgb(243,244,247); //#ecf0f199;;
                // }

                td {

                    span {
                        max-width: 200px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }

                    span.profile {
                        svg {
                            width: 30px;
                            height: 30px;
                            color: #95a5a6;
                        }
                    }

                    span {
                        i {
                            display: block;
                            width: 24px;

                            &.true {
                                color: #27ae60;
                            }

                            &.false {
                                color: #e74c3c;
                            }
                        }
                    }

                    .label {
                        font-size: 14px;
                        // line-height: 24px;
                        padding: 2px 6px;
                        background-color: #EEE;
                        border-radius: 4px;
                        color: #FFF;
                        font-weight: 600;

                        &.actief {
                            background-color: #27ae60;
                        }

                        &.verlopen,
                        &.uitgeschreven {
                            background-color: #e74c3c;
                        }
                    }

                    // span.icon {
                    //     svg {
                    //         width: 18px;
                    //         height: 18px;
                    //         cursor: pointer;
                    //         color: #999;

                    //         &:hover {
                    //             color: #2563EB;
                    //             stroke: 2.5;
                    //         }
                    //     }

                    //     button {
                    //         color: #FFF;
                    //         line-height: 30px;
                    //         padding: 0 12px;
                    //         height: 30px;

                    //         svg {
                    //             color: #FFF;
                    //             margin-right: 5px;
                    //         }
                    //     }

                    // }

                }
            }
        }

        &.clickable {
            tbody {
                tr {
                    cursor: pointer;

                    &:hover {
                        background-color: rgb(243, 244, 247); //#ecf0f199;;
                    }
                }
            }
        }
    }
}
</style>