<template>
    <h1 v-text="$route.meta.title"></h1>
    <!-- <section class="white"> -->
        <!-- <p>&nbsp;</p> -->
    <!-- </section> -->
</template>

<script>

export default {
    name: 'dashboard',
    components: {},
    computed: {
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>
<style lang="scss">


</style>