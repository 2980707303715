<template>
    <div class="requests">
        <div>
            <h1 v-text="title"></h1>
            <section class="white" v-if="isMy">
                <button>Regeling toevoegen</button>
            </section>
            <section class="white">
                <rtable :columns="columns" :data="data"></rtable>
            </section>
        </div>
    </div>
</template>
<script>

import rtable from '@/components/rtable.vue'

export default {
    name: 'requests',
    components: {
        rtable
    },
    computed: {
        session() {
            return this.$store.getters.session
        },
        isMy(){
            return this.$route.meta.menu == "/definitions"
        },
        title(){
            return this.$route.meta.menu == "/definitions" ? "Mijn Regelingen" : this.$route.meta.menu == "/definitions/delegated" ? "Gedelegeerde regelingen"  : this.$route.meta.menu == "/definitions/accredited" ? "Geautorizeerde regelingen" : ""
        }
    },
    data() {
        return {
            columns: [{
                "title": "Titel",
                "field": "title"
            }, {
                "title": "Omschrijving",
                "field": "content"
            }, {
                "title": "Omschrijving",
                "field": "content"
            }],
            data: [{
                "id": "30a57cd1-d32d-4062-a192-25deec61417d",
                "title": "BRL100",
                "content": "De BRL100 is een bedrijfskwalificatie"
            },{
                "id": "30a57cd1-d32d-4062-a192-25deec61417f",
                "title": "BRL 6000-01",
                "content": "..."
            }]
        }
    },
}
</script>