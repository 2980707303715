<template>
    <h1><span>Organisaties</span><i v-html="$icon('chevron-right')"></i><span v-text="organization.name"></span></h1>
    <section class="white">
        <button style="float: right;" class=""><i v-html="$icon('pencil-square')"></i>Bewerken</button>
        <ctable :data="organization" :rows="rows"></ctable>
        <!-- <button style="margin-top: 20px;">Uitnodigen</button> -->
        <!-- <button style="margin-top: 20px;">Uitnodigen</button> -->
    </section>

    <h2>Registraties</h2>

    <section class="white top">
        <span class="label">Filter:</span>
        <div class="formitem">
            <input type="" name="" placeholder="Naam.." />
        </div>
        <button style="float: right;">Nieuwe Registratie</button>
    </section>

    <section class="white bottom">
        <rtable :columns="registrationColumns" :data="registrations"></rtable>
        <!-- <button style="margin-top:20px" class=""><i v-html="$icon('plus')"></i>Nieuwe Registratie</button> -->
    </section>
            <!-- <rtable :columns="columns" :data="data"></rtable> -->
                <!-- </section> -->
            <!-- <h2>Submissions</h2> -->
            <!-- <section class="white" v-for="submission in submissions"> -->
                <!-- <h3 v-text="`From ${submission.from}`"></h3> -->
                <!-- <p class="date" v-text="submission.createdAt"></p> -->
                <!-- <div class="credentials"> -->
                    <!-- <credential v-for="credential in submission.credentials" :credential="credential"></credential> -->
                <!-- </div> -->
            <!-- </section> -->
        <!-- </div> -->
    <!-- </div> -->

</template>
<script>
import credential from '@/components/credential.vue'
import rtable from '@/components/rtable.vue'
import ctable from '@/components/ctable.vue'

export default {
    name: 'request',
    components: {
        credential,
        rtable,
        ctable
    },
    computed: {
        session() {
            return this.$store.getters.session
        },
        organization(){
            return this.$db.organizations.filter(el => {
                return el.url == `/organizations/${this.$route.params.id}`
            }).first({})
        },
        registrations(){
            return this.organization.registrations
        }
    },
    data() {
        return {
            rows: [{
                "title": "Handelsnaam",
                "field": "name"
            }, {
                "title": "KVK",
                "field": "kvk"
            }, {
                "title": "Email",
                "field": "email"
            }, {
                "title": "KvK Status",
                "field": "status"
            }],
            registrationColumns: [
            {
                "title": "Registratie",
                "field": "credential"
            }, {
                "title": "Startdatum",
                "field": "startDate"
            }, {
                "title": "Einddatum",
                "field": "endDate"
            }, {
                "title": "Status",
                "field": "status",
                "type": "label"
            }],

        }
    },
    methods: {}
}
</script>