import { createRouter, createWebHistory } from "vue-router"

import Landing from "./views/landing.vue"

// import Dashboard from "./views/dashboard.vue"
// import Login from "./views/sessions/login.vue"
// import Register from "./views/sessions/register.vue"
// import OTP from "./views/sessions/otp.vue"

import wallet from "./views/wallet.vue"

import definitions from "./views/definitions.vue"
// import definition from "./views/definition.vue"

import suppliers from "./views/suppliers.vue"
import supplier from "./views/supplier.vue"

import persons from "./views/persons.vue"
import person from "./views/person.vue"

import organizations from "./views/organizations.vue"
import organization from "./views/organization.vue"

import employees from "./views/employees.vue"
import employee from "./views/employee.vue"

import requests from "./views/requests.vue"
import submissions from "./views/submissions.vue"
import submission from "./views/submission.vue"
import credential from "./views/credential.vue"

import empty from "./views/empty.vue"

import settings from "./views/settings.vue"

// import Submitters from "./views/submitters.vue"
// import Submitter from "./views/submitter.vue"
// import Submissions from "./views/submissions.vue"

const routes = [{
    path: "/",
    name: "home",
    meta: { menu: "/" },
    component: wallet
},{
    path: "/definitions",
    name: "definitions",
    meta: { menu: "/definitions" },
    component: definitions
},{
    path: "/definitions/delegated",
    name: "definitionsdelegated",
    meta: { menu: "/definitions/delegated" },
    component: definitions
},{
    path: "/definitions/accredited",
    name: "definitionsaccredited",
    meta: { menu: "/definitions/accredited" },
    component: definitions
},{
    path: "/definitions/:id",
    name: "definition",
    meta: { menu: "/definitions" },
    component: supplier
},{
    path: "/credentials/:id",
    name: "credential",
    meta: { menu: "/" },
    component: credential
},{
    path: "/suppliers",
    name: "suppliers",
    meta: { menu: "/suppliers" },
    component: suppliers
},{
    path: "/suppliers/:id",
    name: "supplier",
    meta: { menu: "/suppliers" },
    component: supplier
},{
    path: "/submissions",
    name: "submissions",
    meta: { menu: "/submissions" },
    component: submissions
},{
    path: "/submissions/:id",
    name: "submission",
    meta: { menu: "/submissions" },
    component: submission
},{
    path: "/requests",
    name: "requests",
    meta: { menu: "/requests", title: "Verzoeken" },
    component: requests
},{
    path: "/requests/:id",
    name: "request",
    meta: { menu: "/requests" },
    component: empty
},{
    path: "/discover",
    name: "discover",
    meta: { menu: "/discover", title: "Ontdek" },
    component: empty
},{
    path: "/employees",
    name: "employees",
    meta: { menu: "/employees", title: "Werknemers"  },
    component: employees
},{
    path: "/employees/:id",
    name: "employee",
    meta: { menu: "/employees"},
    component: employee
},{
    path: "/persons",
    name: "persons",
    meta: { menu: "/persons", title: "Personen"  },
    component: persons
},{
    path: "/persons/:id",
    name: "person",
    meta: { menu: "/persons"},
    component: person
},{
    path: "/branches",
    name: "branches",
    meta: { menu: "/branches", title: "Vestigingen"  },
    component: organizations
},{
    path: "/branches/:id",
    name: "branch",
    meta: { menu: "/branches"},
    component: organization
},{
    path: "/organizations",
    name: "organizations",
    meta: { menu: "/organizations", title: "Organisaties"  },
    component: organizations
},{
    path: "/organizations/:id",
    name: "organization",
    meta: { menu: "/organizations"},
    component: organization
},{
    path: "/registrations",
    name: "registrations",
    meta: { menu: "/registrations", title: "Registraties"  },
    component: empty
},{
    path: "/settings",
    name: "settings",
    meta: { menu: "/settings", title: "Instellingen"  },
    component: settings

}]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router