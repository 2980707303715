export default {
    "wallet": [
        { "url": "/credentials/34debda3-c029-482f-988a-b5208d390184", "credential": "EHerkenning EH3", "description": "EHerkenning niveau EH3", "explanation": null, "type": { "id": "89164dc7-32b6-4567-96a7-728995742cbf", "title": "Document" }, "cardtype": "eherkenning", "status": ["Actief"] },
        { "url": "/credentials/6a544b86-80d6-4dcb-87a0-bdb48b5386b8", "credential": "BRL6000-01", "description": "InstallQ BRL6000-01 Woning installaties", "explanation": null, "type": { "id": "89164dc7-32b6-4567-96a7-728995742cbf", "title": "Certificate" }, "status": ["Actief"] },
        { "url": "/credentials/0e3733d7-f09c-4a10-87c1-2872a35d197d", "credential": "BRL6000-02", "description": "InstallQ BRL6000-02 Middelgrote installaties", "explanation": null, "type": { "id": "89164dc7-32b6-4567-96a7-728995742cbf", "title": "Certificate" }, "status": ["Bijna"] },
        // { "id": "44f468dd-fd1b-4986-a372-8e6fc4a8b0f5", "title": "BRL6000-04", "description": "InstallQ BRL6000-04 Woningbouw tot 130kw", "explanation": null, "type": { "id": "89164dc7-32b6-4567-96a7-728995742cbf", "title": "Certificate" } },
        // { "id": "8d56001c-0c8f-40e9-a6fc-2936c05c93fc", "title": "BRL6000-05", "description": "InstallQ BRL6000-05 Utiliteitsbouw tm G16", "explanation": null, "type": { "id": "89164dc7-32b6-4567-96a7-728995742cbf", "title": "Certificate" } },
        { "url": "/credentials/8c2187dd-9a51-4b7f-bfd2-3526d4b6e366", "credential": "BRL1201", "description": "InstallQ BRL1201 Bliksembeveiliging", "explanation": "Uw bedrijf houdt zich bezig met ontwerp, installatie en onderhoud van bliksembeveiligingssystemen, aardingsystemen en overspanningsbeveiliging. Voor een certificaat behoort u te voldoen aan de eisen in BRL1201 ‘Ontwerpen, leveren en installeren van bliksembeveiligingsinstallaties’. Deze BRL bestrijkt het hele traject rondom een bliksembeveiligingsinstallatie: van aanvraag, opname, ontwerp en uitvoering tot oplevering. Ook heeft de BRL betrekking op uitbreiding van een bestaande installatie.", "type": { "id": "89164dc7-32b6-4567-96a7-728995742cbf", "title": "Certificate" }, "status": ["Actief"] }
    ],
    "persons": [{
            "url": "/persons/d58cff33-5dc7-4a19-9c71-bd91273b710b",
            "initials": "AB",
            "lastName": "Jansen",
            "email": "someone@example.com",
            "birthDate": "01-01-1991",
            "registrations": [{
                "credential": "F-Gassen Categorie 1",
                "startDate": "01-01-2023",
                "endDate": "",
                "status": ["Actief"]
            }]
        },
        {
            "url": "/persons/56e718f2-c749-4509-9e95-c4e155834279",
            "initials": "C",
            "lastName": "de Vries",
            "email": "someoneelse@example.com",
            "birthDate": "12-31-1990",
        }
    ],
    "employees": [{
            "url": "/employees/d58cff33-5dc7-4a19-9c71-bd91273b710b",
            "initials": "GE",
            "lastName": "Hendrikus",
            "email": "someone@example.com",
            "birthDate": "01-01-1991",
            "startDate": "01-01-2023",
            "status": ["Actief"],
            "submissions": [{
                "url": "/submissions/5cc07e40-e474-4552-acae-cda358f9da6f",
                "credential": "F-Gassen Categorie 1",
                "type": { "title": "Certificate" },
                "cardtype": "",
                "description": "Werkzaamheden gasverbrandingsinstallaties in verband met preventie koolmonoxide",
                "definition": "",
                "requestedAt": "2023-05-10 15:06:15.587",
                "submitted": true,
                "submittedAt": "2023-05-10 15:06:15.587",
                "status": ["Actief"]
            }]

        },
        {
            "url": "/employees/56e718f2-c749-4509-9e95-c4e155834279",
            "initials": "C",
            "lastName": "de Vries",
            "email": "someoneelse@example.com",
            "birthDate": "12-31-1990",
            "startDate": "01-01-2019",
            "status": ["Actief"]
        }
    ],
    "organizations": [{
        "url": "/organizations/d42ac0c8-5698-4191-adf3-deb0cbafcd43",
        "name": "HeatTransformers",
        "email": "someone@example.com",
        "city": "Den Helder",
        "kvk": "05892726",
        "vnr": "000010735402",
        "status": ["Actief"]
    }, {
        "url": "/organizations/d42ac0c8-5698-4191-adf3-deb0cbafcd46",
        "email": "someone@example.com",
        "name": "Van den Hoogen Engineering B.V.",
        "city": "Rotterdam",
        "kvk": "12782394",
        "vnr": "000012724335",
        "status": ["Actief"]
    }],
    "suppliers": [{
            "url": "/suppliers/d42ac0c8-5698-4191-adf3-deb0cbafcd43",
            "name": "Tom van Roij Elektrotechniek",
            "email": "someone@example.com",
            "type": "Dienstverlener",
            "city": "Den Helder",
            "kvk": "05892726",
            "vnr": "000010735402",
            "status": ["Actief"],
            "requests": [{
                    "url": "/submissions/e42ac0c8-5698-4191-adf3-deb0cbafcd43",
                    "credential": "EHerkenning EH3",
                    "type": { "title": "Document" },
                    "cardtype": "eherkenning",
                    "description": "Beoordelingsrichtlijn voor het certificaat f-gassen voor ondernemingen.",
                    "definition": "Bedrijfsidentificatie",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": true,
                    "submittedAt": "2023-05-10 15:06:15.587",
                    "status": ["Actief"],
                },
                {
                    "url": "/submissions/i42ac078-5698-4191-adf3-deb0cba3cd43",
                    "credential": "BRL K25000",
                    "type": { "title": "Certificate" },
                    "cardtype": "",
                    "description": "Werkzaamheden gasverbrandingsinstallaties in verband met preventie koolmonoxide",
                    "definition": "BRL's",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": true,
                    "submittedAt": "2023-05-10 15:06:15.587",
                    "status": ["Actief"]
                },
                {
                    "url": "/submissions/f42ac0c8-5698-4191-adf3-deb0cbafcd43",
                    "credential": "BRL 100",
                    "type": { "title": "Certificate" },
                    "cardtype": "certificate",
                    "description": "Beoordelingsrichtlijn voor het certificaat f-gassen voor ondernemingen.",
                    "definition": "F-Gassen ",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": false,
                    // "submittedAt": "2023-05-10 15:06:15.587",
                    // "status": ["Actief"]
                }
            ]
        },
        {
            "url": "/suppliers/d42ac0c8-5698-4191-adf3-deb0cbafcd44",
            "name": "Hofstede Holding & Management B.V.",
            "email": "installatiebedrijf@example.com",
            "type": "Onderaannemer",
            "city": "Amsterdam",
            "kvk": "12782394",
            "vnr": "000002518287",
            "status": ["Actief"],
            "requests": [{
                    "url": "/submissions/g42ac0c8-5698-4191-adf3-deb0cbafcd43",
                    "credential": "EHerkenning EH2+",
                    "type": { "title": "Document" },
                    "cardtype": "eherkenning",
                    "description": "EHerkenning Niveau EH2+",
                    "definition": "Bedrijfsidentificatie",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": false
                },
                {
                    "url": "/submissions/h42ac0c8-5698-4191-adf3-deb0cbafcd43",
                    "credential": "BRL 6000-25",
                    "type": { "title": "Certificate" },
                    "cardtype": "certificate",
                    "description": "Gebouwgebonden gasverbrandingstoestel en bijbehorende voorzieningen voor de toevoer van verbrandingslucht en de afvoer van rookgas",
                    "definition": "6000",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": true,
                    "submittedAt": "2023-05-10 15:06:15.587",
                    "status": ["Actief"]
                }
            ]
        },
        {
            "url": "/suppliers/d42ac0c8-5698-4191-adf3-deb0cbafcd45",
            "name": "Loodgietersbedrijf Joosen",
            "email": "someone@example.com",
            "type": "ZZP",
            "city": "Bunnik",
            "kvk": "09123478",
            "vnr": "000012724335",
            "status": ["Actief"],
            "requests": [{
                    "url": "/submissions/i42ac0c8-5698-4191-adf3-deb0cbafcd43",
                    "credential": "BRL K25000",
                    "type": { "title": "Certificate" },
                    "cardtype": "",
                    "description": "Werkzaamheden gasverbrandingsinstallaties in verband met preventie koolmonoxide",
                    "definition": "BRL's",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": true,
                    "submittedAt": "2023-05-10 15:06:15.587",
                    "status": ["Actief"]
                },
                {
                    "url": "/submissions/j42ac0c8-5698-4191-adf3-deb0cbafcd43",
                    "credential": "BRL 6000-25",
                    "type": { "title": "Certificate" },
                    "cardtype": "certificate",
                    "description": "Gebouwgebonden gasverbrandingstoestel en bijbehorende voorzieningen voor de toevoer van verbrandingslucht en de afvoer van rookgas",
                    "definition": "6000",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": true,
                    "submittedAt": "2023-05-10 15:06:15.587",
                    "status": ["Actief"]
                }
            ]
        },
        {
            "url": "/suppliers/d42ac0c8-5698-4191-adf3-deb0cbafcd46",
            "email": "someone@example.com",
            "name": "Van den Hoogen Engineering B.V.",
            "credential": "BRL 100",
            "type": "Uitzendbureau",
            "city": "Rotterdam",
            "kvk": "12782394",
            "vnr": "000012724335",
            "status": ["Actief"],
            "requests": [{
                    "url": "/submissions/k42ac0c8-5698-4191-adf3-deb0cbafcd43",
                    "credential": "EHerkenning EH2+",
                    "type": { "title": "Document" },
                    "cardtype": "eherkenning",
                    "description": "EHerkenning Niveau EH2+",
                    "definition": "Bedrijfsidentificatie",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": true,
                    "submittedAt": "2023-05-10 15:06:15.587",
                    "status": ["Actief"]
                },
                {
                    "url": "/submissions/i42ac0c8-5698-4191-adf3-deb0cba3cd43",
                    "credential": "BRL K25000",
                    "type": { "title": "Certificate" },
                    "cardtype": "",
                    "description": "Werkzaamheden gasverbrandingsinstallaties in verband met preventie koolmonoxide",
                    "definition": "BRL's",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": true,
                    "submittedAt": "2023-05-10 15:06:15.587",
                    "status": ["Actief"]
                },
                {
                    "url": "/submissions/l42ac0c8-5698-4191-adf3-deb0cbafcd43",
                    "credential": "BRL 6000-25",
                    "type": { "title": "Certificate" },
                    "cardtype": "certificate",
                    "description": "Gebouwgebonden gasverbrandingstoestel en bijbehorende voorzieningen voor de toevoer van verbrandingslucht en de afvoer van rookgas",
                    "definition": "6000",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": true,
                    "submittedAt": "2023-05-10 15:06:15.587",
                    "status": ["Verlopen"]
                }
            ]
        },
        {
            "url": "/suppliers/d42ac0c8-5698-4191-adf3-deb0cbafcd47",
            "name": "Kemerink Koeltechniek B.V.",
            "email": "someone@example.com",
            "type": "Dienstverlener",
            "city": "Den Helder",
            "kvk": "60696753",
            "vnr": "000004131983",
            "status": ["Actief"],
            "requests": [{
                    "url": "/submissions/m42ac0c8-5698-4191-adf3-deb0cbafcd43",
                    "credential": "EHerkenning EH2+",
                    "type": { "title": "Document" },
                    "cardtype": "eherkenning",
                    "description": "EHerkenning Niveau EH2+",
                    "definition": "Bedrijfsidentificatie",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": true,
                    "submittedAt": "2023-05-10 15:06:15.587",
                    "status": ["Actief"]
                },
                {
                    "url": "/submissions/n42ac0c8-5698-4191-adf3-deb0cbafcd43",
                    "credential": "BRL 6000-25",
                    "type": { "title": "Certificate" },
                    "cardtype": "certificate",
                    "description": "Gebouwgebonden gasverbrandingstoestel en bijbehorende voorzieningen voor de toevoer van verbrandingslucht en de afvoer van rookgas",
                    "definition": "6000",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": false
                }
            ]
        },
        {
            "url": "/suppliers/d42ac0c8-5698-4191-adf3-deb0cbafcd48",
            "name": "P. van Houtum Service en Onderhoud",
            "email": "installatiebedrijf@example.com",
            "type": "Onderaannemer",
            "city": "Amsterdam",
            "kvk": "17088645",
            "vnr": "000016522621",
            "status": ["Actief"],
            "requests": [{
                    "url": "/submissions/o42ac0c8-5698-4191-adf3-deb0cbafcd43",
                    "credential": "EHerkenning EH2+",
                    "type": { "title": "Document" },
                    "cardtype": "eherkenning",
                    "description": "EHerkenning Niveau EH2+",
                    "definition": "Bedrijfsidentificatie",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": true,
                    "submittedAt": "2023-05-10 15:06:15.587",
                    "status": ["Actief"]
                },
                {
                    "url": "/submissions/p42ac0c8-5698-4191-adf3-deb0cbafcd43",
                    "credential": "BRL 6000-25",
                    "type": { "title": "Certificate" },
                    "cardtype": "certificate",
                    "description": "Gebouwgebonden gasverbrandingstoestel en bijbehorende voorzieningen voor de toevoer van verbrandingslucht en de afvoer van rookgas",
                    "definition": "6000",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": true,
                    "submittedAt": "2023-05-10 15:06:15.587",
                    "status": ["Actief"]
                }
            ]
        },
        {
            "url": "/suppliers/d42ac0c8-5698-4191-adf3-deb0cbafcd49",
            "name": "Loodgietersbedrijf Joosen",
            "email": "someone@example.com",
            "type": "ZZP",
            "city": "Bunnik",
            "kvk": "34985712",
            "vnr": "000016956176",
            "status": ["Uitgeschreven"],
            "requests": [{
                    "url": "/submissions/o42ac0c8-5698-4191-adf3-deb0cbafcd43",
                    "credential": "EHerkenning EH2+",
                    "type": { "title": "Document" },
                    "cardtype": "eherkenning",
                    "description": "EHerkenning Niveau EH2+",
                    "definition": "Bedrijfsidentificatie",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": true,
                    "submittedAt": "2023-05-10 15:06:15.587",
                    "status": ["Verlopen"]
                },
                {
                    "url": "/submissions/p42ac0c8-5698-4191-adf3-deb0cbafcd43",
                    "credential": "BRL 6000-25",
                    "type": { "title": "Certificate" },
                    "cardtype": "certificate",
                    "description": "Gebouwgebonden gasverbrandingstoestel en bijbehorende voorzieningen voor de toevoer van verbrandingslucht en de afvoer van rookgas",
                    "definition": "6000",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": true,
                    "submittedAt": "2023-05-10 15:06:15.587",
                    "status": ["Actief"]
                }
            ]
        },
        {
            "url": "/suppliers/d42ac0c8-5698-4191-adf3-deb0cbafcd53",
            "email": "someone@example.com",
            "name": "Van den Hoogen Engineering B.V.",
            "credential": "BRL 100",
            "type": "Uitzendbureau",
            "city": "Rotterdam",
            "kvk": "09123478",
            "status": ["Actief"],
            "requests": [{
                    "url": "/submissions/o42ac0c8-5698-4191-adf3-deb0cbafcd43",
                    "credential": "EHerkenning EH2+",
                    "type": { "title": "Document" },
                    "cardtype": "eherkenning",
                    "description": "EHerkenning Niveau EH2+",
                    "definition": "Bedrijfsidentificatie",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": true,
                    "submittedAt": "2023-05-10 15:06:15.587",
                    "status": ["Actief"]
                },
                {
                    "url": "/submissions/p42ac0c8-5698-4191-adf3-deb0cbafcd43",
                    "credential": "BRL 6000-25",
                    "type": { "title": "Certificate" },
                    "cardtype": "certificate",
                    "description": "Gebouwgebonden gasverbrandingstoestel en bijbehorende voorzieningen voor de toevoer van verbrandingslucht en de afvoer van rookgas",
                    "definition": "6000",
                    "requestedAt": "2023-05-10 15:06:15.587",
                    "submitted": true,
                    "submittedAt": "2023-05-10 15:06:15.587",
                    "status": ["Actief"]
                }
            ]
        }
    ]
}