<template>
    <h1>Instellingen</h1>
    <h2>Account</h2>
    <button @click="logout">Logout</button>
</template>

<script>

import rtable from '@/components/rtable.vue'

export default {
    name: 'settings',
    components: {
        rtable
    },
    computed: {
    },
    data() {
        return {
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout').then(() => {
                this.$router.push("/")
            }).catch(err => {
                console.error(err)
            })
        }
    }
}
</script>
<style lang="scss">


</style>