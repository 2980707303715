<template>
    <div id="grid">
        <sidebar></sidebar>
        <main class="auth">
            <div class="content">
                <router-view></router-view>
            </div>
        </main>
    </div>
</template>
<script>

import sidebar from '@/components/sidebar.vue'

export default {
    name: 'app',
    components: {
        sidebar,
    },
    computed: {
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
    }
}
</script>
<style lang="scss">

// @import 'reset-css/reset.css';

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap');

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

* {
    box-sizing: border-box;
}

:root {
    --bg: rgb(243, 244, 247);
    --blue: #2563EB;
    --border: 1px solid #bdc3c755;
    --menutext: #606673;
}


a {
    text-decoration: none;
    color: var(--blue);

    &:hover {
        text-decoration: underline;
    }

}

i {
    svg {
        vertical-align: middle;
    }
}

button {
    border: none;
    border-radius: 8px;
    background-color: #3498db;
    cursor: pointer;
    line-height: 36px;
    padding: 0 15px;
    color: #FFF;
    height: 36px;
    font-weight: 600;
    font-size: 1em;


    &:hover {
        background-color: #2980b9;
    }

    +button {
        margin-left: 10px;
    }

    &.right {
        float: right;
    }

    &.red {
        background-color: #e74c3c;

        &:hover {
            background-color: #c0392b;
        }
    }

    i {
        svg {
            width: 18px;
            margin-right: 2px;
        }
    }
}

input,
textarea {
    border: none;
    border-radius: 10px;
    padding: 4px 15px;
    width: 300px;
    resize: none;
    line-height: 24px;
    // background-color: var(--bg);
    border: 2px solid var(--bg);
}

textarea {
    width: 100%;
    height: 100px;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    // margin: 50px;
    max-width: 180px;
    border-radius: 10px;
    padding: 4px 40px 4px 15px;
    border: 2px solid var(--bg);
    line-height: 24px;
    // height: 34px;
    // background: url(https://stackoverflow.com/favicon.ico) 96% / 15% no-repeat #EEE;
    // background-color: #EEE;
}

.formitem {
    position: relative;
    display: inline-block;
    max-width: 300px;
    // line-height: 36px;
}

.formitem.select {
    &:after {
        content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27none%27%20viewBox%3D%270%200%2024%2024%27%20stroke-width%3D%272%27%20stroke%3D%27currentColor%27%20class%3D%27w-6%20h-6%27%3E%3Cpath%20stroke-linecap%3D%27round%27%20stroke-linejoin%3D%27round%27%20d%3D%27M19.5%208.25l-7.5%207.5-7.5-7.5%27%20%2F%3E%3C%2Fsvg%3E");
        // content: "<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'><path stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /></svg>";
        position: absolute;
        top: 8px;
        right: 8px;
        width: 20px;
        height: 20px;
        text-align: center;
    }
}

.formitem+.formitem {
    margin-left: 10px;
}

.formitem {
    // p.title {
    //     font-weight: 500;
    //     margin-bottom: 5px;
    // }
    // margin-bottom: 20px;
}

.label {
    font-weight: 600;
}

.row+.row {
    margin: 15px 0;
}

.row:last-child {
    margin: 0;
}

#grid {
    display: grid;
    height: 100vh;
    grid-template-columns: 300px auto;
    // grid-template-rows: 50px auto;
    grid-template-areas:
        "sidebar main"
}

div.separator {
    width: 100%;
    border-bottom: var(--border);
    margin-top: 20px;
    margin-bottom: 20px;
}

main {
    padding: 40px;
    height: 100vh;
    overflow: scroll;
    // height: 100%;
    background-color: rgb(243, 244, 247); //#ecf0f199;

    .content {
        margin: 0 auto;
        width: 100%;
        max-width: 1200px;
    }

    &.noauth {
        text-align: center;
    }

    &.auth {
        grid-area: main;
    }

    h1 {
        font-family: 'Ubuntu', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 40px;
        color: #000;
        margin-bottom: 20px;

        svg {
            margin: 0 3px 0 10px;
            width: 22px;
            stroke-width: 3px;
            // margin-top: -4px;
        }
    }

    h2 {
        font-family: 'Ubuntu', sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 40px;
        color: #000;
        // padding: 0 4px;
        margin-top: 20px;
        margin-bottom: 10px;
        // background-color: #EEE;

        &.underline {
            border-bottom: var(--border);

        }


        svg {
            margin-right: 10px;
        }
    }

    h3 {
        font-family: 'Ubuntu', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        color: #000;
        // margin-top: 20px;
        // margin-bottom: 10px;
    }

    p.date {
        color: #CCC;
        margin-bottom: 10px;
    }

    p.subtitle {
        margin-top: -15px;
        margin-bottom: 25px;
        color: #999;
        font-size: 16px;

        svg {
            margin-left: 10px;
            width: 18px;
            cursor: pointer;

            &:hover {
                color: #000;
            }
        }
    }

    section {
        padding: 20px 20px 20px 20px;
        border-radius: 15px;

        &.top {
            // border-top-left-radius: 0px;
            // border-top-right-radius: 0px;
            // border-bottom-left-radius: 4px;
            // border-bottom-right-radius: 4px;
        }

        &.bottom {
            // border-top-left-radius: 4px;
            // border-top-right-radius: 4px;
            // border-bottom-left-radius: 0px;
            // border-bottom-right-radius: 0px;
        }

        &.white {
            background-color: #FFF;
        }

        &.grey {
            // background-color: rgba(255,255,255,0.5);
            border: 1px solid rgba(255, 255, 255, 1);
        }

        + section.white {
            margin-top: 20px;

            &.bottom {
                margin-top: 10px;
            }
        }

        p {
            line-height: 25px;
        }

        ul {
            list-style: disc;
            margin-left: 20px;

            li {
                line-height: 25px;
            }
        }
    }

    .credentials {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        a {
            flex: 0 0 auto;
        }
    }

}
</style>