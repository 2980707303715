<template>
    <h1><span>Wallet</span><i v-html="$icon('chevron-right')"></i><span v-text="credential.credential"></span></h1>
    <credential :credential="credential"></credential>
    <h2>Details</h2>
    <section class="white">
        <ctable :data="credential" :rows="rows"></ctable>
        <button style="margin-top: 20px">Verifieer nu</button>
    </section>
</template>
<script>

import credential from '@/components/credential.vue'
import ctable from '@/components/ctable.vue'

export default {
    name: 'submitters',
    components: {
        credential,
        ctable
    },
    computed: {
        session() {
            return this.$store.getters.session
        },
        credential(){
            for (var i = 0; i < this.$db.wallet.length; i++) {
                if(this.$db.wallet[i].url == `/credentials/${this.$route.params.id}`){
                    return this.$db.wallet[i]
                }
            }
            return {}
        }
    },
    data() {
        return {
            rows: [
            // {
            //     "title": "Leverancier",
            //     "field": "name"
            {
                "title": "Titel",
                "field": "credential"
            }, {
                "title": "Omschrijving",
                "field": "description"
            // }, {
            //     "title": "Type",
            //     "field": "type"
            // }, {
            //     "title": "Verzoek",
            //     "field": "definition"
            // }, {
            //     "title": "Opgevraagd op",
            //     "field": "requestedAt"
            // }, {
            //     "title": "Verstuurd op",
            //     "field": "submittedAt"
            }],
        }
    },
}
</script>

<style lang="scss">
.credential + section {
    // margin-top: 40px;
}
</style>