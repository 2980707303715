<template>
    <aside class="aside">
        <router-link to="/">
            <div class="logo">
                <img src="/logofull.png">
                <!-- <span>Vakpaspoort</span> -->
            </div>
        </router-link>
        <!-- <div class="account"> -->
            <!-- <img src="@/assets/equans.png" /> -->
            <!-- <div class="title">Equans</div> -->
        <!-- </div> -->
        <ul>
            <li v-for="item in menu">
                <div v-if="item.type == 'h'" class="h" v-text="item.title"></div>
                <router-link v-else :to="item.url || ''" :class="[{'router-link-active': isActive(item.url)}]">
                    <i v-html="$icon(item.icon)"></i>
                    <span v-text="item.title"></span>
                </router-link>
            </li>
        </ul>
        <ul class="bottom">
            <li>
                <router-link to="settings" xclass="[{'router-link-active': isActive('settings')}]">
                    <i v-html="$icon('cog6-tooth')"></i>
                    <span>Instellingen</span>
                </router-link>
            </li>
        </ul>
    </aside>
</template>
<script>
export default {
    name: 'sidebar',
    props: {},
    computed: {
        session() {
            return this.$store.getters.session || localStorage.getItem("session") || "" // || "f1a1882f-5818-4d97-b6b1-d743737a8300"
        },
        auth() {
            return { headers: `session: ${this.session}` }
        }
    },

    data() {
        return {
            menu: [{
                    title: "Wallet",
                    url: "/",
                    icon: "wallet",
                },
                {
                    title: "Ontdek",
                    url: "/discover",
                    icon: "sparkles",
                },
                {
                    title: "Regelingen",
                    type: "h"
                },
                {
                    title: "Mijn regelingen",
                    url: "/definitions",
                    icon: "cube",
                },
                {
                    title: "Geautoriseerd",
                    url: "/definitions/accredited",
                    icon: "cube-transparent",
                },
                {
                    title: "Registraties",
                    type: "h"
                },
                {
                    title: "Alle registraties",
                    url: "/registrations",
                    icon: "circle-stack",
                },
                {
                    title: "Personen",
                    url: "/persons",
                    icon: "users",
                },
                {
                    title: "Vestigingen",
                    url: "/branches",
                    icon: "building-office",
                },
                {
                    title: "Organisaties",
                    url: "/organizations",
                    icon: "building-office2",
                },
                {
                    title: "Relaties",
                    type: "h"
                },
                {
                    title: "Werknemers",
                    url: "/employees",
                    icon: "user-group",
                },
                {
                    title: "Leveranciers",
                    url: "/suppliers",
                    icon: "truck",
                },
                // {
                //     title: "Documenten",
                //     url: "/documents",
                //     icon: "document-check",
                // },
                // {
                //     title: "Extern",
                //     type: "h"
                // },
                // {
                //     title: "Verzoeken",
                //     url: "/requests",
                //     icon: "qr-code",
                // },
                // {
                //     title: "Gedeelde Documenten",
                //     url: "/submissions",
                //     icon: "document-arrow-down",
                // }
            ],
        }
    },
    methods: {
        isActive(matcher) {
            return this.$route.meta.menu == matcher
        },
    }
}
</script>
<style lang="scss">
aside.aside {
    position: relative;
    grid-area: sidebar;
    overflow: scroll;
    padding: 0 15px;

    svg {
        position: relative;
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }

    div.logo {
        img {
            width: 190px;
        }
        // font-family: 'Ubuntu', sans-serif;
        // line-height: 40px;
        // color: #000;
        // font-weight: 700;
        // font-size: 18px;
        padding: 20px 20px 20px 20px;
        // border-bottom: var(--border);
        // color: var(--blue);

        // i {
        //     padding: 5px 3px 8px 7px;
        //     text-align: center;
        //     background-color: var(--blue);
        //     border-radius: 10px;
        //     color: #FFF;
        //     margin-right: 7px;
        // }

        // svg {
        //     width: 24px;
        //     stroke-width: 2px;
        // }
    }

    .account {
        display: flex;
        padding: 10px 10px;
        margin-bottom: 15px;
        border-bottom: var(--border);

        img {
            width: 36px;
            height: 36px;
            margin-right: 10px;
        }

        .title {
            font-family: 'Ubuntu', sans-serif;
            line-height: 36px;
            color: #333; //var(--menutext);
            font-size: 1.1em;
            font-weight: 700;
        }
    }



    .reddot:before {
        display: block;
        position: absolute;
        width: 5px;
        height: 5px;
        background-color: red;
    }



    div.subtitle {
        font-size: 14px;
        color: #999;
        padding: 0 20px;
        margin-bottom: 20px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        &.bottom {
            position: absolute;
            bottom: 10px;
            width: 250px;
        }

        li {
            list-style: none;
            margin: 0;
            padding: 0;
            line-height: 36px;

            .spacer {
                width: 30px;
                display: inline-block;
            }

            &.divider {
                height: 10px;
                border-bottom: var(--border);
                // margin-bottom: 10px;
                margin: 0 30px 10px 30px;
            }

            a,
            div {
                text-decoration: none;
                display: block;
                width: 100%;
                color: #333;
                border-radius: 5px;
                line-height: 36px;
                color: var(--menutext);
                font-weight: 600;
                width: 100%;
                padding: 0 15px;

                i {
                    width: 30px;
                    height: 36px;
                    line-height: 36px;
                    display: inline-block;

                    svg {
                        width: 25px;
                        text-align: center;
                        // color: #666;
                    }
                }

                span {
                    padding-left: 15px;
                }

                &:hover {
                    // background-color: rgba(243,244,247,50);
                    color: var(--blue);
                    ;
                }

                &.router-link-active {
                    background-color: var(--bg);
                    // color: var(--blue);
                }

                &.h {
                    padding: 0 10px;
                    line-height: 30px;
                    margin-top: 30px;
                    font-weight: 800;
                    font-size: 0.8em;
                    text-transform: uppercase;
                    // color: #333;
                }
            }
        }
    }

    // .bottom {
    //     position: absolute;
    //     bottom: 0;
    //     padding: 10px 20px;
    //     width: 100%;
    //     border-top: var(--border);

    //     select {
    //         border: none;
    //         font-size: 17px;
    //         color: #aaa;
    //         box-sizing: border-box;
    //         appearance: none;
    //         // width: 250px;
    //         font-family: inherit;
    //         cursor: pointer;
    //         user-select: none;
    //         background-color: transparent;
    //         outline: none;

    //         &:focus {
    //             border: none;
    //             background-color: transparent;
    //             outline: none
    //         }
    //     }
    // }
    // a.logout {
    //     position: absolute;
    //     bottom: 20px;
    //     left: 20px;
    //     color: #666;

    //     &:hover {
    //         text-decoration: underline;
    //     }
    // }


    // .buttons {
    //     padding: 20px;
    // }
}
</style>