<template>
    <div class="ctable">
        <table>
            <tbody>
                <tr v-for="row in rows">
                    <td class="header">
                        <span v-text="row.title"></span>
                    </td>
                    <td>
                        <span v-if="row.type == 'label'"><span :class="['label', data[row.field].first('').toLowerCase()]" v-text="data[row.field].first('')"></span></span>
                        <span v-else-if="row.type == 'url'"><a :href="data[row.field]" v-text="data[row.field]" target="_blank"></a></span>
                        <span v-else v-text="data[row.field]"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
// import dayjs from 'dayjs'

export default {
    name: 'ctable',
    props: {
        data: {
            type: Object,
            default: () => { return {} }
        },
        rows: {
            type: Array,
            default: () => { return [] }
        }
    },
    computed: {
        // parsedData() {
        //     let keys = Object.keys(this.data)
        //     return keys.map(key => {
        //         return {
        //             key,
        //             value: this.data[key],
        //             type: typeof this.data[key] == "object" ? "label" : typeof this.data[key] == "string" && this.data[key].substring(0,4) == "http" ? "url" : "text"
        //         }
        //     })
        // },
    },
    data() {
        return {}
    },
    methods: {
        route(row) {
            if (row.id) {
                this.$router.push(this.$route.path + "/" + row.id)
            }
        },
        // dayjs
    }
}
</script>
<style lang="scss">
.ctable {
    // padding-top: 20px;
    // background-color: #FFF;

    table {
        // width: 100%;

        tr,
        th,
        td {
            // line-height: 26px;
            text-align: left;
            border-bottom: 1px solid var(--bg);
        }

        td.header {
            font-weight: 600;
        }

        td,
        th {
            padding: 10px 15px;
            vertical-align: middle;
            line-height: 20px;

            // div.txt {
            //     // line-height: 26px;
            //     padding: 3px 0;
            //     display: block;
            // }
            // div.img {
            //     // line-height: 26px;
            //     // margin: 3px 0;
            //     // display: block;
            // }
        }

        thead {
            // border-bottom: var(--border);
            font-weight: 700;

            tr {
                th {
                    // background-color: rgb(243,244,247); //#ecf0f199;;
                }
            }
        }

        tbody {
            tr {
                // cursor: pointer;

                // &:hover {
                // background-color: rgb(243,244,247); //#ecf0f199;;
                // }

                td {
                    span.profile {
                        svg {
                            width: 30px;
                            height: 30px;
                            color: #95a5a6;
                        }
                    }

                    span {
                        i {
                            display: block;
                            width: 25px;

                            &.true {
                                color: #27ae60;
                            }

                            &.false {
                                color: #e74c3c;
                            }
                        }
                    }

                    .label {
                        font-size: 14px;
                        // line-height: 24px;
                        padding: 2px 6px;
                        background-color: #EEE;
                        border-radius: 4px;
                        color: #FFF;
                        font-weight: 600;

                        &.actief {
                            background-color: #27ae60;
                        }

                        &.verlopen, &.uitgeschreven {
                            background-color: #e74c3c;
                        }
                    }

                    // span.icon {
                    //     svg {
                    //         width: 18px;
                    //         height: 18px;
                    //         cursor: pointer;
                    //         color: #999;

                    //         &:hover {
                    //             color: #2563EB;
                    //             stroke: 2.5;
                    //         }
                    //     }

                    //     button {
                    //         color: #FFF;
                    //         line-height: 30px;
                    //         padding: 0 12px;
                    //         height: 30px;

                    //         svg {
                    //             color: #FFF;
                    //             margin-right: 5px;
                    //         }
                    //     }

                    // }

                }
            }
        }
    }
}
</style>