<template>
    <h1>Wallet</h1>
    <div class="credentials" style="flex-wrap: nowrap; flex-flow: column wrap;">
        <credential v-for="credential in credentials" :credential="credential" ></credential>
    </div>
</template>
<script>
import credential from '@/components/credential.vue'

export default {
    name: 'wallet',
    components: {
        credential
    },
    computed: {
        session() {
            return this.$store.getters.session
        },
        credentials(){
            return this.$db.wallet
            // for (var i = 0; i < this.$db.length; i++) {
            //     if(this.$db[i].requests){
            //         for (var j = 0; j < this.$db[i].requests.length; j++) {
            //             if(this.$db[i].requests[j].url == `/submissions/${this.$route.params.id}`){
            //                 this.$db[i].requests[j].name = this.$db[i].name
            //                 return this.$db[i].requests[j]
            //             }
            //         }
            //     }
            // }
            // return {}
        }

    },
    data() {
        return {
            // credentials: [
            //     { "url": "/credentials/34debda3-c029-482f-988a-b5208d390184", "credential": "EHerkenning EH3", "description": "EHerkenning niveau EH3", "explanation": null, "type": { "id": "89164dc7-32b6-4567-96a7-728995742cbf", "title": "Document" }, "cardtype": "eherkenning" },
            //     { "url": "/credentials/6a544b86-80d6-4dcb-87a0-bdb48b5386b8", "credential": "BRL6000-01", "description": "InstallQ BRL6000-01 Woning installaties", "explanation": null, "type": { "id": "89164dc7-32b6-4567-96a7-728995742cbf", "title": "Certificate" } },
            //     { "url": "/credentials/0e3733d7-f09c-4a10-87c1-2872a35d197d", "credential": "BRL6000-02", "description": "InstallQ BRL6000-02 Middelgrote installaties", "explanation": null, "type": { "id": "89164dc7-32b6-4567-96a7-728995742cbf", "title": "Certificate" } },
            //     // { "id": "44f468dd-fd1b-4986-a372-8e6fc4a8b0f5", "title": "BRL6000-04", "description": "InstallQ BRL6000-04 Woningbouw tot 130kw", "explanation": null, "type": { "id": "89164dc7-32b6-4567-96a7-728995742cbf", "title": "Certificate" } },
            //     // { "id": "8d56001c-0c8f-40e9-a6fc-2936c05c93fc", "title": "BRL6000-05", "description": "InstallQ BRL6000-05 Utiliteitsbouw tm G16", "explanation": null, "type": { "id": "89164dc7-32b6-4567-96a7-728995742cbf", "title": "Certificate" } },
            //     { "url": "/credentials/8c2187dd-9a51-4b7f-bfd2-3526d4b6e366", "credential": "BRL1201", "description": "InstallQ BRL1201 Bliksembeveiliging", "explanation": "Uw bedrijf houdt zich bezig met ontwerp, installatie en onderhoud van bliksembeveiligingssystemen, aardingsystemen en overspanningsbeveiliging. Voor een certificaat behoort u te voldoen aan de eisen in BRL1201 ‘Ontwerpen, leveren en installeren van bliksembeveiligingsinstallaties’. Deze BRL bestrijkt het hele traject rondom een bliksembeveiligingsinstallatie: van aanvraag, opname, ontwerp en uitvoering tot oplevering. Ook heeft de BRL betrekking op uitbreiding van een bestaande installatie.", "type": { "id": "89164dc7-32b6-4567-96a7-728995742cbf", "title": "Certificate" } }
            // ]
        }
    },
}
</script>
<style lang="scss">
</style>