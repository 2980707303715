import { createStore } from 'vuex'
// import axios from './axios.js'

export default createStore({
	state: {
		email: "",
		token: "",
		me: {}
		// authorizations: [{id: "uuid", token: ""}],
		// active: "uuid"
	},
	mutations: {
		email(state, email) {
			state.email = email
		},
		me(state, me) {
			state.me = me
		},
		token(state, token) {
			state.token = token
		},
		login(state, token) {
			localStorage.setItem("token", token)
			state.token = token
		},
		logout(state) {
			state.email = ""
			state.token = ""
			state.me = {}
			localStorage.removeItem("token")
		},
	},
	actions: {
		// login({commit, state, dispatch}, otp){
		// 	return new Promise((resolve, reject) => {
		// 		axios.post("/login", {email: state.email, otp, client: "desktop"}).then(response => {
		// 			commit("login", response.data.token || "")
		// 			dispatch("getme").then(() => {
		// 				resolve()
		// 			}).catch(err => {
		// 				reject(err)
		// 			})
		// 		}).catch(err => {
		// 			reject(err)
		// 		})
		// 	})
		// },
		// logout({commit, getters}){
		// 	return new Promise((resolve, reject) => {
		// 		if(getters.token){
		// 			axios({method: 'post', url: '/logout', headers: `token: ${getters.token}`}).then(response => {
		// 				commit("logout")
		// 				resolve()
		// 			}).catch(err => {
		// 				reject(err)
		// 			})
		// 		}else{
		// 			reject("no token")
		// 		}
		// 	})
		// },
		// getme({commit, getters, dispatch}) {
		// 	return new Promise((resolve, reject) => {
		// 		if(getters.token){
		// 			axios.get("/me", {headers: `token: ${getters.token}`}).then(response => {
		// 				commit("me", response.data)
		// 				resolve()
		// 			}).catch(err => {
		// 				commit("logout")
		// 				reject(err)
		// 			})
		// 		}else{
		// 			reject("no token")
		// 		}
		// 	})
		// },
	},
	getters: {
		email: (state) => {
			return state.email
		},
		activeSession: (state) => {
			return state.me.id ? true : false
		},
		token: (state) => {
			return state.token || localStorage.getItem("token") || ""
			// if(state.active == me.id){

			// }else{
			// 	token = state.authorizations.filter(el => e,.id == state.active).token
			// }
		},
		// headerAuth: (state) => {
		//     // let session = state.session || localStorage.getItem("token") || ""
		//     // return session ? { headers: `session: ${session}` } : null
		//     return {}
		// },
		me: (state) => {
			return state.me
		},
	},
	modules: {
	}
})
