<template>
    <h1><span>Gedeelde Documenten</span><i v-html="$icon('chevron-right')"></i><span v-text="submission.credential"></span></h1>
    <credential :credential="submission"></credential>
    <h2>Details</h2>
    <section class="white">
        <ctable :data="submission" :rows="rows"></ctable>
        <button v-if="submission.submitted" style="margin-top: 20px">Verifieer nu</button>
        <button v-if="!submission.submitted" style="margin-top: 20px">Opnieuw opvragen</button>
    </section>
</template>
<script>

import credential from '@/components/credential.vue'
import rtable from '@/components/rtable.vue'
import ctable from '@/components/ctable.vue'

export default {
    name: 'submitters',
    components: {
        credential,
        rtable,
        ctable
    },
    computed: {
        session() {
            return this.$store.getters.session
        },
        submission(){
            for (var i = 0; i < this.$db.suppliers.length; i++) {
                if(this.$db.suppliers[i].requests){
                    for (var j = 0; j < this.$db.suppliers[i].requests.length; j++) {
                        if(this.$db.suppliers[i].requests[j].url == `/submissions/${this.$route.params.id}`){
                            this.$db.suppliers[i].requests[j].name = this.$db.suppliers[i].name
                            return this.$db.suppliers[i].requests[j]
                        }
                    }
                }
            }
            for (var i = 0; i < this.$db.employees.length; i++) {
                if(this.$db.employees[i].submissions){
                    for (var j = 0; j < this.$db.employees[i].submissions.length; j++) {
                        if(this.$db.employees[i].submissions[j].url == `/submissions/${this.$route.params.id}`){
                            this.$db.employees[i].submissions[j].name = this.$db.employees[i].name
                            return this.$db.employees[i].submissions[j]
                        }
                    }
                }
            }
            return {}
        }
    },
    data() {
        return {
            credential: { "id": "6a544b86-80d6-4dcb-87a0-bdb48b5386b8", "title": "EHerkenning EH3", "description": "EHerkenning Betrouwbaarheid EH3", "explanation": null, "type": { "id": "89164dc7-32b6-4567-96a7-728995742cbf", "title": "Document" }, "cardtype": "eherkenning", "status": "ok" },
            rows: [{
                "title": "Leverancier",
                "field": "name"
            }, {
                "title": "Titel",
                "field": "credential"
            }, {
                "title": "Omschrijving",
                "field": "description"
            // }, {
            //     "title": "Type",
            //     "field": "type"
            }, {
                "title": "Verzoek",
                "field": "definition"
            }, {
                "title": "Opgevraagd op",
                "field": "requestedAt"
            }, {
                "title": "Verstuurd op",
                "field": "submittedAt"
            }],
            columns: [{
                "title": "Name",
                "field": "name"
            }, {
                "title": "Type",
                "field": "type"
            }, {
                "title": "Content",
                "field": "content"
            }, {
                "title": "Requested at",
                "field": "createdAt"
            }],
            data: [{
                "id": "30a57cd1-d32d-4062-a192-25deec61417e",
                "name": "Verzekering",
                "type": "Document",
                "content": "{'input_descriptors: {}'}",
                "createdAt": "2023-05-10 15:06:15.587"
            },{
                "id": "30a57cd1-d32d-4062-a192-25deec61417f",
                "name": "BRL 9001",
                "type": "Certificate",
                "content": "{'input_descriptors: {}'}",
                "createdAt": "2023-05-10 15:06:15.587"
            }]

        }
    },
}
</script>

<style lang="scss">
.credential + section {
    // margin-top: 40px;
}
</style>