<template>
    <h1>Leveranciers</h1>
    <section class="white top" style="position: relative;">
        <!-- <span class="label">Submitter:</span> -->
        <!-- <div class="formitem select"> -->
            <!-- <select><option>All</option><option>email@example.com</option><option>someoneelse@example.com</option></select> -->
        <!-- </div> -->
        <span class="label">Filter:</span>
        <div class="formitem">
            <input type="" name="" placeholder="Naam.." v-model="filterSearch" />
        </div>
        <button style="float: right;"><i v-html="$icon('plus')"></i>Leverancier Toevoegen</button>
    </section>
    <section class="white bottom">
        <rtable :columns="columns" :data="filteredSuppliers"></rtable>
    </section>
    <pagination :pages="10" :page="7"></pagination>

        <!-- <section class="white"> -->
        <!-- </section> -->
        <!-- <section class="credentials white"> -->
            <!-- <credential v-for="credential in credentials" :credential="credential"></credential> -->
        <!-- </section> -->
    <!-- </div> -->
</template>

<script>
import rtable from '@/components/rtable.vue'
import credential from '@/components/credential.vue'
import pagination from '@/components/pagination.vue'

export default {
    name: 'suppliers',
    components: {
        credential,
        rtable,
        pagination
    },
    computed: {
        session() {
            return this.$store.getters.session
        },
        suppliers(){
            return this.$db.suppliers
        },
        filteredSuppliers(){
            return this.suppliers.filter(el => {
                return this.filterSearch ? el.name.toLowerCase().indexOf(this.filterSearch.toLowerCase()) >= 0 : true
            })
        }
    },
    data() {
        return {
            filterSearch: "",
            columns: [{
                "title": "Handelsnaam",
                "field": "name"
            }, {
                "title": "Type",
                "field": "type"
            }, {
                "title": "KVK",
                "field": "kvk"
            }, {
                "title": "Email",
                "field": "email"
            }, {
                "title": "KvK Status",
                "field": "status"
            }]
        }
    }
}
</script>

<style lang="scss">
input.dropdown {
    position: relative;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: attr(icon);
    }
}

span.label {
    padding-right: 10px;
}

.formitem + span {
    padding-left: 20px;
}
</style>