<template>
    <h1><span>Leveranciers</span><i v-html="$icon('chevron-right')"></i><span v-text="supplier.name"></span></h1>
    <section class="white">
        <button style="float: right;" class="red"><i v-html="$icon('trash')"></i>Verwijderen</button>
        <ctable :data="supplier" :rows="rows"></ctable>
        <!-- <button style="margin-top: 20px;">Uitnodigen</button> -->
        <!-- <button style="margin-top: 20px;">Uitnodigen</button> -->
    </section>
    <h2>Gedeelde Documenten</h2>
    <section class="white top">
        <span class="label">Filter:</span>
        <div class="formitem">
            <input type="" name="" placeholder="Naam.." />
        </div>
        <button style="float: right;"><i v-html="$icon('plus')"></i>Document Opvragen</button>
    </section>
    <section class="white bottom">
        <rtable :columns="submissionColumns" :data="submissions"></rtable>
    </section>
            <!-- <rtable :columns="columns" :data="data"></rtable> -->
                <!-- </section> -->
            <!-- <h2>Submissions</h2> -->
            <!-- <section class="white" v-for="submission in submissions"> -->
                <!-- <h3 v-text="`From ${submission.from}`"></h3> -->
                <!-- <p class="date" v-text="submission.createdAt"></p> -->
                <!-- <div class="credentials"> -->
                    <!-- <credential v-for="credential in submission.credentials" :credential="credential"></credential> -->
                <!-- </div> -->
            <!-- </section> -->
        <!-- </div> -->
    <!-- </div> -->

</template>
<script>
import credential from '@/components/credential.vue'
import rtable from '@/components/rtable.vue'
import ctable from '@/components/ctable.vue'

export default {
    name: 'request',
    components: {
        credential,
        rtable,
        ctable
    },
    computed: {
        session() {
            return this.$store.getters.session
        },
        supplier(){
            return this.$db.suppliers.filter(el => {
                return el.url == `/suppliers/${this.$route.params.id}`
            }).first({})
        },
        submissions(){
            return this.supplier.requests
        }
    },
    data() {
        return {
            rows: [{
                "title": "Handelsnaam",
                "field": "name"
            }, {
                "title": "Type",
                "field": "type"
            }, {
                "title": "KVK",
                "field": "kvk"
            }, {
                "title": "Vestigingsnummer",
                "field": "vnr"
            }, {
                "title": "KvK Status",
                "field": "status",
                "type": "label"
            }, {
                "title": "Email",
                "field": "email"
            }],
            submissionColumns: [
            {
                "title": "Document",
                "field": "credential"
            }, {
                "title": "Verzoek",
                "field": "definition"
            }, {
                "title": "Opgevraagd op",
                "field": "requestedAt"
            }, {
                "title": "Ontvangen",
                "field": "submitted"
            }, {
                "title": "Ontvangen op",
                "field": "submittedAt"
            }, {
                "title": "Status",
                "field": "status",
                "type": "label"
            }],

        }
    },
    methods: {}
}
</script>