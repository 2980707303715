<template>
    <h1><span>Werknemers</span><i v-html="$icon('chevron-right')"></i><span v-text="employee.lastName"></span></h1>
    <section class="white">
        <button style="float: right;" class=""><i v-html="$icon('pencil-square')"></i>Bewerken</button>
        <ctable :data="employee" :rows="rows"></ctable>
        <!-- <button style="margin-top: 20px;">Uitnodigen</button> -->
        <!-- <button style="margin-top: 20px;">Uitnodigen</button> -->
    </section>

    <h2>Gedeelde Documenten</h2>
    <section class="white top">
        <span class="label">Filter:</span>
        <div class="formitem">
            <input type="" name="" placeholder="Naam.." />
        </div>
        <button style="float: right;"><i v-html="$icon('plus')"></i>Document Opvragen</button>
    </section>
    <!-- <div class="separator"></div> -->
    <section class="white bottom">
        <rtable :columns="submissionColumns" :data="submissions"></rtable>
    </section>

    <h2>Registraties</h2>
    <section class="white top">
        <span class="label">Filter:</span>
        <div class="formitem">
            <input type="" name="" placeholder="Naam.." />
        </div>
        <button style="float: right;"><i v-html="$icon('plus')"></i>Nieuwe Registratie</button>
    </section>
    <!-- <div class="separator"></div> -->

    <section class="white bottom">
        <rtable :columns="registrationColumns" :data="registrations"></rtable>
        <!-- <button style="margin-top:20px" class=""><i v-html="$icon('plus')"></i>Nieuwe Registratie</button> -->
    </section>


</template>
<script>
import credential from '@/components/credential.vue'
import rtable from '@/components/rtable.vue'
import ctable from '@/components/ctable.vue'

export default {
    name: 'request',
    components: {
        credential,
        rtable,
        ctable
    },
    computed: {
        session() {
            return this.$store.getters.session
        },
        employee(){
            return this.$db.employees.filter(el => {
                return el.url == `/employees/${this.$route.params.id}`
            }).first({})
        },
        submissions(){
            return this.employee.submissions || []
        }
    },
    data() {
        return {
            rows: [{
                "title": "Initialen",
                "field": "initials"
            }, {
                "title": "Achternaam",
                "field": "lastName"
            }, {
                "title": "Email",
                "field": "email"
            }, {
                "title": "Geboortedatum",
                "field": "birthDate"
            }, {
                "title": "Startdatum",
                "field": "startDate"
            }, {
                "title": "Einddatum",
                "field": "endDate"
            }, {
                "title": "Status",
                "field": "status",
                "type": "label"
            }],
            registrationColumns: [
            {
                "title": "Registratie",
                "field": "credential"
            }, {
                "title": "Startdatum",
                "field": "startDate"
            }, {
                "title": "Einddatum",
                "field": "endDate"
            }, {
                "title": "Status",
                "field": "status",
                "type": "label"
            }],
            submissionColumns: [
            {
                "title": "Document",
                "field": "credential"
            // }, {
            //     "title": "Verzoek",
            //     "field": "definition"
            }, {
                "title": "Opgevraagd op",
                "field": "requestedAt"
            }, {
                "title": "Ontvangen",
                "field": "submitted"
            }, {
                "title": "Ontvangen op",
                "field": "submittedAt"
            }, {
                "title": "Status",
                "field": "status",
                "type": "label"
            }],

        }
    },
    methods: {}
}
</script>