<template>
    <router-link :to="credential.url" :class="['credential', credential.cardtype]">
        <h4 v-text="credential.type ? credential.type.title : ''"></h4>
        <h3 v-text="credential.credential"></h3>
        <p class="description" v-text="credential.description"></p>
        <div class="tag" :class="status">
            <i class="icon" v-html="icon"></i>
        </div>
        <!-- <p class="explanation" v-text="credential.explanation"></p> -->
    </router-link>
</template>
<script>
export default {
    name: 'navbar',
    props: {
        credential: {
            type: Object,
            default: () => { return {type: {}} }
        },
    },
    computed: {
        status(){
            return this.credential.status && typeof this.credential.status == "object" ? this.credential.status.first("") : ""
        },
        icon(){
            return this.status == "Actief" ? this.$icon('check-badge', 'solid') : this.status == "Bijna" ? this.$icon('exclamation-circle', 'solid') : this.status == "Verlopen" ? this.$icon('x-circle', 'solid') : this.$icon('question-mark-circle', 'solid')
        }
    },
    methods: {
    }
}
</script>
<style lang="scss">
.credential {
    position: relative;
    border-radius: 10px;
    display: block;
    width: 300px;
    height: 180px;
    padding: 14px;

    background: linear-gradient(
        330deg,
        hsl(205deg 100% 66%) 0%,
        hsl(206deg 98% 63%) 11%,
        hsl(206deg 96% 60%) 22%,
        hsl(206deg 95% 57%) 33%,
        hsl(207deg 93% 54%) 44%,
        hsl(207deg 91% 50%) 56%,
        hsl(207deg 100% 47%) 67%,
        hsl(209deg 100% 46%) 78%,
        hsl(211deg 99% 46%) 89%,
        hsl(215deg 87% 48%) 100%
    );

    &:hover {
        text-decoration: none;

        background: linear-gradient(
            300deg,
            hsl(205deg 100% 66%) 0%,
            hsl(206deg 98% 63%) 11%,
            hsl(206deg 96% 60%) 22%,
            hsl(206deg 95% 57%) 33%,
            hsl(207deg 93% 54%) 44%,
            hsl(207deg 91% 50%) 56%,
            hsl(207deg 100% 47%) 67%,
            hsl(209deg 100% 46%) 78%,
            hsl(211deg 99% 46%) 89%,
            hsl(215deg 87% 48%) 100%
        );
    }

    &.eherkenning {
        background: rgb(16,64,138);
        background: linear-gradient(155deg, rgba(16,64,138,1) 0%, rgba(16,64,138,1) 35%, rgba(222,3,103,1) 100%);

        &:hover {
            background: linear-gradient(200deg, rgba(16,64,138,1) 0%, rgba(16,64,138,1) 35%, rgba(222,3,103,1) 100%);
        }
    }

    h3 {
        margin: 0 0 10px 0;
        color: #FFF;

        a {
            color: #FFF;
        }
    }    

    h4 {
        color: #fff;
        font-weight: 500;
        margin-bottom: 5px;        
    }

    p {
        position: absolute;
        left: 14px;
        bottom: 14px;
        width: 270px;
        color: #FFF;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;        
    }

    .tag {
        position: absolute;
        top: 0;
        right: 25px;
        background-color: rgba(255,255,255,1);
        height: 30px;
        border-bottom-left-radius: 13px;
        border-bottom-right-radius: 13px;
        width: 26px;

        .icon {
            margin-top: 6px;
            width: 100%;
            display: block;
            text-align: center;

            svg {
                width: 20px;
                color: #999;
            }
        }

        &.Actief .icon svg {
            color: #27ae60;
        }

        &.Bijna .icon svg {
            color: #f39c12;
        }

        &.Verlopen .icon svg {
            color: #e74c3c;
        }
    }


    // transition: background 0.3s;

}
</style>