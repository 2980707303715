<template>
    <h1>Verzoeken</h1>
    <section class="white">
        <rtable :columns="columns" :data="data"></rtable>
    </section>
</template>

<script>

import rtable from '@/components/rtable.vue'

export default {
    name: 'requests',
    components: {
        rtable
    },
    computed: {
    },
    data() {
        return {
            columns: [{
                "title": "Name",
                "field": "name"
            }, {
                "title": "Content",
                "field": "content"
            }],
            data: [{
                "id": "30a57cd1-d32d-4062-a192-25deec61417d",
                "name": "EHerkenning",
                "content": "{'input_descriptors: {}'}"
            },{
                "id": "30a57cd1-d32d-4062-a192-25deec61417e",
                "name": "Verzekering",
                "content": "{'input_descriptors: {}'}"
            },{
                "id": "30a57cd1-d32d-4062-a192-25deec61417f",
                "name": "BRL 6000-01",
                "content": "{'input_descriptors: {}'}"
            }]
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout').then(() => {
                this.$router.push("/")
            }).catch(err => {
                console.error(err)
            })
        }
    }
}
</script>
<style lang="scss">


</style>