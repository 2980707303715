<template>
    <h1>Vakpaspoort</h1>
    <section>
        <button @click="$router.push('/login')">Login</button>
        <button @click="$router.push('/register')">Register</button>
    </section>
</template>

<script>

export default {
    name: 'dashboard',
    components: {},
    computed: {
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>
<style lang="scss">


</style>