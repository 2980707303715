<template>
    <h1>Gedeelde Documenten</h1>
    <section class="white top" style="position: relative;">
        <span class="label">Verstrekker:</span>
        <div class="formitem select">
            <select v-model="filterSupplier">
                <option value="">Alle</option>
                <option v-for="supplier in suppliers" v-text="supplier" :value="supplier"></option>
            </select>
        </div>
        <span class="label">Document:</span>
        <div class="formitem select">
            <select v-model="filterDocument">
                <option value="">Alle</option>
                <option v-for="doc in credentialDocs" v-text="doc" :value="doc"></option>
                <!-- <option>BRL100</option> -->
                <!-- <option>BRL6000-00</option> -->
                <!-- <option>EHerkenning</option> -->
            </select>
        </div>
<!--         <span class="label">Verzoek:</span>
        <div class="formitem select">
            <select>
                <option>Alle</option>
                <option>Identificatie</option>
                <option>Verlopen</option>
            </select>
        </div>
 -->
        <span class="label">Status:</span>
        <div class="formitem select">
            <select v-model="filterStatus">
                <option value="">Alle</option>
                <option>Actief</option>
                <option>Verlopen</option>
                <option>Opgeschort</option>
                <option>Ingetrokken</option>
            </select>
        </div>
        <button style="float: right;"><i v-html="$icon('plus')"></i>Document Opvragen</button>
    </section>
    <section class="white bottom">
        <rtable :columns="columns" :data="filteredSubmissions"></rtable>
    </section>
    <pagination :pages="10" :page="7"></pagination>
</template>

<script>

import rtable from '@/components/rtable.vue'
import credential from '@/components/credential.vue'
import pagination from '@/components/pagination.vue'

export default {
    name: 'submitters',
    components: {
        credential,
        rtable,
        pagination
    },
    computed: {
        session() {
            return this.$store.getters.session
        },
        submissions(){
            let data = []
            for (var i = 0; i < this.$db.suppliers.length; i++) {
                if(this.$db.suppliers[i].requests){
                    for (var j = 0; j < this.$db.suppliers[i].requests.length; j++) {
                        this.$db.suppliers[i].requests[j].name = this.$db.suppliers[i].name
                        data.push(this.$db.suppliers[i].requests[j])
                    }
                }
            }
            return data
        },
        filteredSubmissions(){
            return this.submissions.filter(el => {
                return this.filterSupplier ? el.name == this.filterSupplier : this.filterDocument ? el.credential == this.filterDocument : this.filterStatus ? el.status ? el.status.first("") == this.filterStatus : false : true
            })
        },
        suppliers(){
            return this.$db.suppliers.map(el => {
                return el.name
            })
        },
        credentialDocs(){
            let keys = this.submissions.reduce((acc, curr) => {
                acc[curr.credential] = ""
                return acc
            }, {})
            return Object.keys(keys)
        }
    },
    data() {
        return {
            filterSupplier: "",
            filterDocument: "",
            filterStatus: "",
            columns: [
            {
                "title": "Document",
                "field": "credential"
            }, {
                "title": "Verstrekker",
                "field": "name"
            }, {
                "title": "Verzoek",
                "field": "definition"
            }, {
                "title": "Opgevraagd op",
                "field": "requestedAt"
            }, {
                "title": "Ontvangen",
                "field": "submitted"
            }, {
                "title": "Ontvangen op",
                "field": "submittedAt"
            }, {
                "title": "Status",
                "field": "status",
                "type": "label"
            }]
        }
    },
}
</script>
<style lang="scss">
input.dropdown {
    position: relative;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: attr(icon);
    }
}

span.label {
    padding-right: 10px;
}

.formitem+span {
    padding-left: 20px;
}
</style>