<template>
    <h1>Werknemers</h1>
    <section class="white top" style="position: relative;">
        <!-- <span class="label">Submitter:</span> -->
        <!-- <div class="formitem select"> -->
            <!-- <select><option>All</option><option>email@example.com</option><option>someoneelse@example.com</option></select> -->
        <!-- </div> -->
        <span class="label">Filter:</span>
        <div class="formitem">
            <input type="" name="" placeholder="Naam.." v-model="filterSearch" />
        </div>
        <button style="float: right;"><i v-html="$icon('plus')"></i>Werknemer Toevoegen</button>
    </section>
    <section class="white bottom">
        <rtable :columns="columns" :data="employees"></rtable>
    </section>
    <pagination :pages="10" :page="7"></pagination>

        <!-- <section class="white"> -->
        <!-- </section> -->
        <!-- <section class="credentials white"> -->
            <!-- <credential v-for="credential in credentials" :credential="credential"></credential> -->
        <!-- </section> -->
    <!-- </div> -->
</template>

<script>
import rtable from '@/components/rtable.vue'
import pagination from '@/components/pagination.vue'

export default {
    name: 'employees',
    components: {
        rtable,
        pagination
    },
    computed: {
        session() {
            return this.$store.getters.session
        },
        employees(){
            return this.$db.employees
        },
        // filteredSuppliers(){
        //     return this.suppliers.filter(el => {
        //         return this.filterSearch ? el.name.toLowerCase().indexOf(this.filterSearch.toLowerCase()) >= 0 : true
        //     })
        // }
    },
    data() {
        return {
            filterSearch: "",
            columns: [{
                "title": "Initialen",
                "field": "initials"
            }, {
                "title": "Achternaam",
                "field": "lastName"
            }, {
                "title": "Email",
                "field": "email"
            }, {
                "title": "Geboortedatum",
                "field": "birthDate"
            }, {
                "title": "Startdatum",
                "field": "startDate"
            }, {
                "title": "Status",
                "field": "status",
                "type": "label"
            }]
        }
    }
}
</script>

<style lang="scss">
input.dropdown {
    position: relative;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: attr(icon);
    }
}

span.label {
    padding-right: 10px;
}

.formitem + span {
    padding-left: 20px;
}
</style>