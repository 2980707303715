<template>
    <div class="pagination">
        <!-- <div class="first"><i v-html="$icon('chevron-double-left')"></i></div> -->
        <!-- <div class="prev"><i v-html="$icon('chevron-left')"></i></div> -->
        <div class="page">1</div>
        <div class="dots">...</div>
        <div class="page">4</div>
        <div class="page active">5</div>
        <div class="page">6</div>
        <div class="dots">...</div>
        <div class="page">12</div>
        <!-- <div class="next"><i v-html="$icon('chevron-right')"></i></div> -->
        <!-- <div class="last"><i v-html="$icon('chevron-double-right')"></i></div> -->
    </div>
</template>
<script>
export default {
    name: 'pagination',
    props: {
        pages: { type: Number, default: 0 },
        page: { type: Number, default: 0 }
    },
    data() {
        return {}
    },
    methods: {
    }
}
</script>
<style lang="scss">
.pagination {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    gap: 5px;

    > div {
        border-radius: 10px;
        background-color: #FFF;
        color: var(--menutext);
        height: 36px;
        cursor: pointer;

        &:hover {
            background-color: var(--blue);
            color: #FFF;
        }

        i {
            display: block;
            width: 20px;
            height: 20px;
            margin: 9px;

            svg {
                stroke-width: 2px;
            }
        }

        &.dots {
            line-height: 36px;
            background-color: transparent;
            padding: 0 5px;
        }

        &.page {
            line-height: 36px;
            padding: 0 12px;

            &.active {
                background-color: var(--blue);
                color: #FFF;
            }
        }
    }
}
</style>